import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/Dashboard/';

class OwnerDashboardService {
  getPlayroomStatistics() {
    return ApiService.get(`${API_URL}PlayroomStatistics`);
  }
  getOrderOverview(orderOverviewMonth: string) {
    return ApiService.get(`${API_URL}GetOrderOverview?date=${orderOverviewMonth}`);
  }
  getOrder() {
    return ApiService.get(`${API_URL}GetOrder`);
  }
  getAmount() {
    return ApiService.get(`${API_URL}GetAmount`);
  }
  getCustomer() {
    return ApiService.get(`${API_URL}GetCustomer`);
  }
}

export default new OwnerDashboardService();
