


























































































import Vue, { VueConstructor } from 'vue';
import OwnerDashboardService from '@/services/owner/OwnerDashboardService';
import HostOrderOverview from '@/components/dashboard/HostOrderOverview.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

class OwnerDashboardCustomerData {
  loading = true;
  data = {
    customerCount: 0,
    successOrderCustomerCount: 0,
  };
}
class OwnerDashboardOrderData {
  loading = true;
  data = {
    futureSuccessOrderCount: 0,
    orderCount: 0,
  };
}
class OwnerDashboardAmountData {
  loading = true;
  data = {
    pastSum: 0,
    futureSum: 0,
  };
}

class WeekData {
  startAt!: string;
  visitCount!: number;
}

class PlayroomVisitStatistic {
  name!: String;
  weekDataList!: WeekData[];
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: { HostOrderOverview },
  data() {
    return {
      loading: false,
      customerData: new OwnerDashboardCustomerData(),
      orderData: new OwnerDashboardOrderData(),
      amountData: new OwnerDashboardAmountData(),
      playroomVisitStatistics: [] as PlayroomVisitStatistic[],
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostDashboard.meta.title').toString(),
    };
  },
  methods: {
    playroomVisitLabel(weekDataList: WeekData[]) {
      return weekDataList.map((data) => 'Woche ' + this.formatIsoDateTime(data.startAt, 'd.M.'));
    },

    playroomVisitData(weekDataList: WeekData[]) {
      return weekDataList.map((data) => data.visitCount);
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostDashboard.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      OwnerDashboardService.getCustomer().then((res) => {
        this.customerData.loading = false;
        this.customerData.data = res.value;
      });
      OwnerDashboardService.getAmount().then((res) => {
        this.amountData.loading = false;
        this.amountData.data = res.value;
      });
      OwnerDashboardService.getOrder().then((res) => {
        this.orderData.loading = false;
        this.orderData.data = res.value;
      });
      OwnerDashboardService.getPlayroomStatistics().then((res) => {
        this.orderData.loading = false;
        this.playroomVisitStatistics = res.value.playroomVisitStatistics as PlayroomVisitStatistic[];
      });
      this.setBreadCrumb();
    },
  },
});
