




































import Vue, { VueConstructor } from 'vue';
import OwnerDashboardService from '@/services/owner/OwnerDashboardService';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { parse } from 'date-fns';
class OrderOverviewData {
  loading = true;
  data = {
    lineItems: [],
    availableMonths: [],
  };
}
export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  data() {
    return {
      loading: false,
      orderOverviewData: new OrderOverviewData(),
      orderOverviewMonth: '',
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    orderOverviewMonth() {
      this.loadData();
    },
  },
  computed: {
    orderOverviewMonthOptions(): { value: string; label: string }[] {
      if (this.orderOverviewData.data.availableMonths.length == 0) {
        return [{ value: '', label: '' }];
      }
      return this.orderOverviewData.data.availableMonths.map((month) => {
        const date = parse(month, 'yyyy-MM-dd', new Date());
        const label = this.formatDateTime(date, 'LLLL yyyy');
        return { value: month, label };
      });
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      OwnerDashboardService.getOrderOverview(this.orderOverviewMonth).then((res) => {
        this.orderOverviewData.loading = false;
        this.orderOverviewData.data = res.value;
        if (!this.orderOverviewMonth) {
          const availableMonthCount = this.orderOverviewData.data.availableMonths.length;
          if (availableMonthCount > 0) {
            this.orderOverviewMonth = this.orderOverviewData.data.availableMonths[availableMonthCount - 1];
          }
        }
      });
    },
  },
});
